@import '~libs/styles/src/lib/app/common/variables.scss';
.table-responsive {
  overflow-x: inherit !important;
}

.obw-table {

  .row-clickable {
    cursor: pointer;
  }

  .check-column {
    text-align: center;
  }

  .checkbox {
    margin: 0;
  }

  &.table .filter-row th {
    padding: 0.3rem;
  }

  &.selectable td:first-child,
  &.selectable th:first-child {
    width: 2.5rem;
  }

  .table-avatar {
    width: 2rem;
    border-radius: 50%;
  }

  .fa-play {
    color: $brand-success;
  }

  .fa-stop {
    color: $brand-danger;
  }

  input {
    font-weight: normal;
  }
}

.obw-table-toolbar {
  display: flex;
  padding: 1em;

  .obw-table-actions {
    flex: 1;
    display: flex;
    align-items: center;

    .form-inline {
      .form-group {
        margin-right: 1rem;

        .control-label {
          margin-right: 0.5rem;
        }
      }
    }

    .empty-actions {
      height: 31px;
    }    
  }

  .dropdown, button, a.btn {
    margin-right: 0.5rem;
  }
}

.obw-table-fixed-height {
  position: relative;

  tbody {
    position: absolute;
    overflow-y: scroll;
    display: block;
    width: 100%;
    height: calc(100% - 3.3rem);
  }

  tr {
    width: 100%;
    display: inline-table;
    table-layout: fixed;
  }

  td:first-child:nth-last-child(1) {
    width: 100%;
  }

  td:first-child:nth-last-child(2),
  td:first-child:nth-last-child(2) ~ td {
    width: 50%;
  }

  td:first-child:nth-last-child(3),
  td:first-child:nth-last-child(3) ~ td {
    width: 33.3333%;
  }

  td:first-child:nth-last-child(4),
  td:first-child:nth-last-child(4) ~ td {
    width: 25%;
  }

  td:first-child:nth-last-child(5),
  td:first-child:nth-last-child(5) ~ td {
    width: 20%;
  }

  &.selectable td:first-child,
  &.selectable th:first-child {
    width: 2.5rem;
  }

  &.selectable td:first-child:nth-last-child(2) ~ td,
  &.selectable th:first-child:nth-last-child(2) ~ th {
    width: calc(100% - 2.5rem);
  }

  &.selectable td:first-child:nth-last-child(3) ~ td,
  &.selectable th:first-child:nth-last-child(3) ~ th {
    width: calc((100% - 2.5rem) / 2);
  }

  &.selectable td:first-child:nth-last-child(4) ~ td,
  &.selectable th:first-child:nth-last-child(4) ~ th {
    width: calc((100% - 2.5rem) / 3);
  }

  &.selectable td:first-child:nth-last-child(5) ~ td,
  &.selectable th:first-child:nth-last-child(5) ~ th {
    width: calc((100% - 2.5rem) / 4);
  }
}
