@import '../bootstrap/functions';
@import '../bootstrap/variables';
@import '../bootstrap/mixins';
@import '../app/common/variables';
// svgicon
@import '../app/trucadence/icons/icon-style';

@import '../app/custom/custom-styles';

// Color system
$primary: $gradient-baltic-sea-start;
$brand-primary: $gradient-baltic-sea-start;

$gradient-brand-start: $gradient-baltic-sea-start;
$gradient-brand-end: $gradient-baltic-sea-end;

$colors: (
  'purple': $purple,
  'pink': $pink,
  'yellow': $yellow,
  'green': $green,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'inverse': $inverse,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'success-light': $success-light,
  'success-dark': $success-dark,
  'info-light': $info-light,
  'info-dark': $info-dark,
  'warning-light': $warning-light,
  'warning-dark': $warning-dark,
  'danger-light': $danger-light,
  'danger-dark': $danger-dark,
  'green-light': $green-light,
  'green-dark': $green-dark,
  'pink-light': $pink-light,
  'pink-dark': $pink-dark,
  'purple-light': $purple-light,
  'purple-dark': $purple-dark,
  'inverse-light': $inverse-light,
  'inverse-dark': $inverse-dark,
  'yellow-light': $yellow-light,
  'yellow-dark': $yellow-dark,
  'color-ocean': $color-ocean,
  'color-trueblue': $color-trueblue,
  'color-cyan': $color-cyan,
  'color-powder': $color-powder,
  'color-sky': $color-sky,
  'color-white': $color-white,
  'color-charcoal': $color-charcoal,
  'color-rock': $color-rock,
  'color-grey': $color-grey,
  'color-wolf': $color-wolf,
  'color-mako': $color-mako,
  'color-silver': $color-silver,
  'color-grass': $color-grass,
  'color-mint': $color-mint,
  'color-perry': $color-perry,
  'color-mist': $color-mist,
  'color-poppy': $color-poppy,
  'color-rouge': $color-rouge,
  'color-orange': $color-orange,
  'color-orange-light': $color-orange-light,
  'color-ocean-light': $color-ocean-light,
  'color-ocean-dark': $color-ocean-dark,
  'color-trueblue-light': $color-trueblue-light,
  'color-trueblue-dark': $color-trueblue-dark,
  'color-cyan-light': $color-cyan-light,
  'color-cyan-dark': $color-cyan-dark,
  'color-powder-light': $color-powder-light,
  'color-powder-dark': $color-powder-dark,
  'color-sky-light': $color-sky-light,
  'color-sky-dark': $color-sky-dark,
  'color-white-light': $color-white-light,
  'color-white-dark': $color-white-dark,
  'color-charcoal-light': $color-charcoal-light,
  'color-charcoal-dark': $color-charcoal-dark,
  'color-rock-light': $color-rock-light,
  'color-rock-dark': $color-rock-dark,
  'color-grey-light': $color-grey-light,
  'color-grey-dark': $color-grey-dark,
  'color-wolf-light': $color-wolf-light,
  'color-wolf-dark': $color-wolf-dark,
  'color-mako-light': $color-mako-light,
  'color-mako-dark': $color-mako-dark,
  'color-silver-light': $color-silver-light,
  'color-silver-dark': $color-silver-dark,
  'color-grass-light': $color-grass-light,
  'color-grass-dark': $color-grass-dark,
  'color-mint-light': $color-mint-light,
  'color-mint-dark': $color-mint-dark,
  'color-perry-light': $color-perry-light,
  'color-perry-dark': $color-perry-dark,
  'color-mist-light': $color-mist-light,
  'color-mist-dark': $color-mist-dark,
  'color-poppy-light': $color-poppy-light,
  'color-poppy-dark': $color-poppy-dark,
  'color-rouge-light': $color-rouge-light,
  'color-rouge-dark': $color-rouge-dark,
  'color-midnight-express': $color-midnight-express,
  'color-dark-midnight-express': $color-dark-midnight-express,
  'color-light-scarlet': $color-light-scarlet,
  'color-sunset': $color-sunset,
  'color-koncert-white': $color-koncert-white,
  'color-primary-shade': $color-primary-shade,
  'color-secondary-shade': $color-secondary-shade,
  'color-persian-blue': $color-persian-blue,
);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'color-midnight-express': $color-midnight-express,
  'color-dark-midnight-express': $color-dark-midnight-express,
  'color-persian-blue': $color-persian-blue,
  'color-light-scarlet': $color-light-scarlet,
  'color-sunset': $color-sunset,
  'color-koncert-white': $color-koncert-white,
  'color-primary-shade': $color-primary-shade,
  'color-secondary-shade': $color-secondary-shade,
);

$theme-gradients: 'blood-orange' $gradient-blood-orange-start
    $gradient-blood-orange-end,
  'blood-orange-light' $gradient-blood-orange-light-start
    $gradient-blood-orange-light-end,
  'turquoise-dream' $gradient-turquoise-dream-start
    $gradient-turquoise-dream-end,
  'purple-rain' $gradient-purple-rain-start $gradient-purple-rain-end,
  'baltic-sea' $gradient-baltic-sea-start $gradient-baltic-sea-end,
  'gothic-sky' $gradient-gothic-sky-start $gradient-gothic-sky-end,
  'brand' $gradient-brand-start $gradient-brand-end;

@import './theme-koncert.scss';

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

@each $color, $value in $colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $start, $end in $theme-gradients {
  @include fa-gradient-koncert-variant('.fa-gradient-#{$color}', $start, $end);
}

@each $color, $start, $end in $theme-gradients {
  @include bg-gradient-koncert-variant('.bg-gradient-#{$color}', $start, $end);
}

@each $color, $start, $end in $theme-gradients {
  @include bg-gradient-koncert-variant(
    '.badge-gradient-#{$color}',
    $start,
    $end
  );
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn {
  &.btn-secondary {
    border-color: $btn-secondary-border;
  }
  &.btn-outline-secondary {
    border-color: $btn-secondary-border !important;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      theme-color-level($color, $alert-bg-level),
      theme-color-level($color, $alert-border-level),
      theme-color-level($color, $alert-color-level)
    );
  }
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

@each $color, $value in $theme-colors {
  @include list-group-item-variant(
    $color,
    theme-color-level($color, -9),
    theme-color-level($color, 6)
  );
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant(
    $color,
    theme-color-level($color, $table-bg-level),
    theme-color-level($color, $table-border-level)
  );
}

@each $color, $value in $theme-colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}

/* Forms */
.form-check-input {
  margin-top: 3px;
} // To vertical align center

.centred-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.column-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Slidebar Menu
.sidebar-subnav.nav-floating {
  > li {
    &:hover {
      > a {
        color: $primary;
      }
    }
  }
}
.sidebar-nav {
  > li {
    > a {
      &:focus,
      &:hover {
        color: $primary;
      }
    }
  }
}

// Line graph overflow hidden issue
.sparklink-graph svg {
  overflow: visible;
}
.btn-outline-primary {
  &:hover {
    color: $color-koncert-white;
  }
}
.hoverShow {
  display: none;
}
.card-body {
  &:hover {
    > .hoverShow {
      display: flex;
    }
  }
}

// React dual box
.react-dual-listbox {
  .rdl-move-right {
    color: #94ce69;
  }
  .rdl-move-left {
    color: #f15b28;
  }
  .rdl-filter,
  .rdl-control {
    border-color: #dde6e9;
    border-radius: 0.25rem;
    padding: 6px 12px;
  }
  .rdl-control option {
    padding: 3px 5px;
  }
}

// Overwrite the swal button style
.swal-title {
  color: inherit;
  font-size: 18px;
}
.swal-text {
  color: inherit;
  font-size: 14px;
}
.swal-button {
  padding: 0.375rem 1rem;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid transparent;
  &.btn-danger {
    &:hover {
      background-color: #e4450f;
    }
  }
}

// Toggle-btn color
.custom-control-label {
  cursor: pointer;
}
.custom-control-input ~ .custom-control-label {
  &::before {
    background-color: $gray !important;
    border-color: $color-white !important;
    top: 0.1rem !important;
    height: 1.15rem !important;
    width: 2rem !important;
    border-radius: 0.6rem !important;
  }
}

.custom-control-input ~ .custom-control-label {
  &::after {
    background-color: $secondary !important;
    border-radius: 50% !important;
    left: calc(-2.25rem + 4px) !important;
  }
}

.custom-control-input:checked ~ .custom-control-label {
  &::after {
    background-color: $secondary !important;
  }
}

.custom-control-input:checked ~ .custom-control-label {
  &::before {
    background-color: $brand-success !important;
    border-color: $color-white;
  }
}

.tooltip-inner {
  &.tooltip-varient {
    max-width: 350px; /* To increase width of the tooltip body */
  }
}
